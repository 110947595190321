
import $ from 'jquery';
window.$ = window.jQuery = $;

const primaryCollectionField = require('./admin/primaryCollectionField');

$(function() {
    primaryCollectionField.init();
});

$(document).on('click', '.modalToggle', function (e) {
    Livewire.emit('toggleModal', $(this).attr('data-modal-target'));
});

$(function () {
    tinymce.init({
        selector: 'textarea',
        height: 500,
        menubar: true,
        plugins: [
            'advlist autolink lists link image charmap print preview anchor',
            'searchreplace visualblocks code fullscreen',
            'insertdatetime media table contextmenu paste code'
        ],
        toolbar: 'undo redo | insert | styleselect | bold italic | alignleft aligncenter alignright alignjustify |  bullist numlist outdent indent | link image',
        content_css: 'https://www.tinymce.com/css/codepen.min.css',
        images_upload_url: '/utility/image-upload',
        images_upload_credentials: true,
        automatic_uploads: true,
        file_picker_types: 'image'
    });

    const $tableFilterInputs = $('input.live-search');
    if ($tableFilterInputs.length) {
        $tableFilterInputs.each(function(i, input) {

            const $table = $(this).closest('table');
            const $rows = $table.find('tbody tr');
            const $input = $(input);

            $input.on('keyup', function() {

                const query = $input.val().toLowerCase();

                if (query.length < 3) {
                    $rows.show();
                } else {
                    $rows.each(function(i, row) {

                        if ($(row).html().toLowerCase().indexOf(query) > -1) {
                            $(row).show();
                        } else {
                            $(row).hide();
                        }
                        
                    });
                }
            });
        });
    }
});