const primaryCollectionField = {

    /**
     * Get elements
     */
    $collectionInputs: $('.js-collection-input'),
    $primaryCollectionSelect: $('.js-primary-collection-select'),

    /**
     * Register listeners
     */
    init: function() {
        const self = this;

        // Set up available options on load
        self.setAvailableOptions();

        // Listen for changes on the collection inputs
        self.$collectionInputs.on('change', function() {
            self.setAvailableOptions();
        });

    },

    /**
     * Set the available options based on which inputs are currently checked
     */
    setAvailableOptions: function() {

        const self = this;

        // Get the checked inputs
        const checkedInputs = self.$collectionInputs.filter(':checked');

        // Get the values of the checked inputs
        const checkedValues = checkedInputs.map(function() {
            return $(this).val();
        }).get();

        // Get the options in the select
        const options = self.$primaryCollectionSelect.find('option');

        // Loop through the options
        options.each(function() {

            // Get the option value
            const optionValue = $(this).val();

            // Skip the blank first option
            if (optionValue == '') return;

            // If the option value is in the checked values
            if (checkedValues.includes(optionValue)) {

                // Enable the option
                $(this).prop('disabled', false);

            } else {

                // Disable the option
                $(this).prop('disabled', true);

                // If the option is selected
                if ($(this).is(':selected')) {

                    // Deselect the option
                    $(this).prop('selected', false);

                    // Select the first available option
                    self.$primaryCollectionSelect.find('option:enabled').first().prop('selected', true);

                }

            }

        });

    },

};

module.exports = primaryCollectionField;